import Axios from "axios";

const params = {
  baseURL: "",
  headers: {
    "Content-Type": "application/json"
  }
};

export default class Http {
  static createAxiosInstance(
    auth = true,
    urlBase = false,
    authmaster = false,
    authlojaintegrada = false,
    authdetailemp = false,
    id = false,
    authToken = false
  ) {
    params.baseURL = process.env.VUE_APP_URL_API;
    if (urlBase) {
      params.baseURL = urlBase;
    }
    if (authmaster) {
      params.headers.authorization = "Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb";
    }
    if (authlojaintegrada) {
      params.headers.chave_api = authlojaintegrada;
    }
    if (authdetailemp) {
      params.headers.companyid;
    }
    if (id) {
      params.headers.companyId = id;
    }
    if (authToken) {
      params.headers.authorization = `Bearer ${authToken}`;
    }

    if (auth)
      params.headers.Authorization =
        localStorage.getItem("session") !== null
          ? `Bearer ${JSON.parse(localStorage.getItem("session")).token}`
          : "";

    if (localStorage.getItem("session") != null)
      params.headers.companyId = JSON.parse(
        localStorage.getItem("session")
      ).companies[0].companyId;

    const axios = Axios.create(params);

    axios.interceptors.request.use(
      config => {
        return config;
      },
      error => {
        return error;
      }
    );

    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location = "https://www.zeeintegra.com.br/";
          return error.response;
        }
        return error.response;
      }
    );

    return axios;
  }
}
